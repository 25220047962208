@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';


@media (min-width: $breakpoint-xs) {
    #sequence {
        p {
            text-align: center;
            line-height: 25px;
            margin-bottom: 0;
            
        }
    
        h2 {
            text-align: center;
        }
    
        
    
        .step-decription {
            font-size: $font-size-md;
            line-height: 20px;
            margin: 30px 0;
            margin-bottom: 100px;
        }
    
        .sequence-number {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white-color;
            background-color: $primary-color;
            width: 70px;
            height: 70px;
            font-size: $font-size-lg;
            border: 5px solid $gray-color;
            border-radius: 100%;
        }
    
        .icon-container {
    
            > div:first-child {
                width: 70% !important;    
                height: 250px;   
                padding-top: 60px;        
            }
    
            p {
                width: 150px;
            }
             
               
        }
    }
}

@media (min-width: $breakpoint-sm) {
    #sequence {
        p {
            text-align: center;
            line-height: 25px;
            margin-bottom: 0;
            
        }
    
        h2 {
            text-align: center;
        }
    
        
    
        .step-decription {
            font-size: $font-size-sm;
            line-height: 20px;
            margin: 30px 0;
        }
    
        .sequence-number {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white-color;
            background-color: $primary-color;
            width: 70px;
            height: 70px;
            font-size: $font-size-lg;
            border: 5px solid $gray-color;
            border-radius: 100%;
        }
    
        .icon-container {
    
            > div:first-child {
                width: 100% !important;    
                height: 250px;   
                padding-top: 60px;        
            }
    
            p {
                width: 150px;
            }
             
               
        }
    }
}

