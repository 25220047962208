

.steuer-header {
    .header-slogan {
        font-weight: 600 !important;            
    }

    height: auto !important;    
    
    .steuererkalrung-section {
        padding: 0 !important;
    }
    
}
