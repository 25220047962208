@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

#loandisclaimer {
    background: $lightblue-color;
    padding: 30px 0;
    text-align: center;

    p {
        opacity: .7;
        line-height: 20px;
    }
}