@import '../../../assets/styles/variables';

.contentblock {
    position: relative;
    padding: 30px;
    background-color: white;   
    border-radius: $border-radius;
    box-sizing: border-box;
}

@media (min-width: $breakpoint-xs) {
    .contentblock {
        width: 100%;
    }
}

@media (min-width: $breakpoint-sm) {
    .contentblock {
        width: auto;
    }
}