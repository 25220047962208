// Footer styles
$footer-color: $primarydark-color;

$desktop-top-padding: 5vh;
$mobile-top-padding: 5vh;

$logo-desktop-height: 5vh;
$logo-mobile-height: 5vh;

@media (min-width: $breakpoint-xs) {
    footer {
        padding-top: $mobile-top-padding;
        width: 100%;
        background-color: $footer-color;
        padding-bottom: 3vh;

        .phone-row {
            justify-content: center;
        }

        hr {
            @include dynamic-border-color($footer-color);
            opacity: .2;
            margin: 10px 0;
            margin-top: 3vh;
        }

        h4,
        span {
            @include dynamic-text-color($footer-color);
            text-align: center;
        }

        li {
            margin: 10px 0;    
            text-align: center;        
        }

        .link {
            @include dynamic-text-color($footer-color);
            &:hover {              
                @include dynamic-text-color($footer-color);  
                @include dynamic-text-decoration-color($footer-color);
            }
        }

        #footer-logo {
            height: $logo-mobile-height;
            margin-bottom: 3vh;
            cursor: pointer;

        }

        .social-icons, #contact-icon {
            @include dynamic-invert($footer-color);
        }

        .social-icons {
            width: 50%;
            margin-top: 2vh;
            margin-bottom: 3vh;                  
        }

        #contact-icon {
            margin-right: 10px;
            transform: translateY(-1px);
        }
    }
}

@media (min-width: $breakpoint-sm) {
    footer {
        padding-top: $desktop-top-padding;
        padding-bottom: 3vh;

        .phone-row {
            justify-content: flex-start;
        }

        #footer-logo {
            height: $logo-desktop-height;


        }

        span, li, h4     {
            text-align: left;
        }

        .social-icons {
            width: 100%;
            margin: 0;
        }
    }
}