@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

$accordion-default-color: $secondary-color;

@media (min-width: $breakpoint-xs) {
    .accordion-heading {        
        width: 100%;        
        cursor: pointer;        
        padding: 1rem;
        box-sizing: border-box;
        margin: 4px 0;
        background: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.178);        
        font-weight: $font-weight-normal;

        @include dynamic-text-color($accordion-default-color);        
        font-weight: 400;
    }                

    .accordion-content {
        width: 100%;
        font-weight: 300;
    }

    .accordion-down-icon {
        margin-left: 20px;      
        
        path {
            @include dynamic-fill-color($accordion-default-color);             
        }
               
    }

}

@media (min-width: $breakpoint-sm) {

}