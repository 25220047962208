@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

$list-padding: 40px;
$list-item-padding: 7px;
$list-border-radius: $border-radius;
$list-shadow: $box-shadow;

.dropdown {        
    position: relative;  
    

    .dropdown-down-icon {
        margin-left: 10px;        
    }
      
    button {
        border: none;
        padding: 0;
        background: none;           
        z-index: $zindex-max;
        height: 50px;
        
        div {
            margin-left: 10px;
        }

    }

    hr {
        border: 1px solid black;
        padding: 0;
        margin: 0;
        opacity: .1;
    }
    
    ul {
        position: absolute;
        background-color: white;        
        left: 50%;
        top: 100%;        
        transform: translate(-50%, 2%);   

        border-radius: $list-border-radius;
        padding: $list-padding;                
        z-index: $zindex-max + 1;   
        box-shadow: $list-shadow;
        
        li {
            padding: $list-item-padding;   
            
            span {
                display: flex;
                align-items: center;                

                div {
                    margin-right: 10px;
                }
                
            }
        }        
    }
}

    
