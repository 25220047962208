@import '../../assets/styles/variables';


#applicationPage { 
    padding-top: 150px;   
    .loanAmount {
        border: 1px solid $secondary-color;
        border-radius: $border-radius;
        width: 100%;
        text-align: center;
        font-weight: bold;
        padding: 10px 0;
        position: relative;
        top: 10px;        
        
        
    }

    .payment-container {        
        justify-content: flex-start !important;
        
    }

    .b {
        padding: 15px 0;
        font-weight: bold;
        color: rgba($color: #000000, $alpha: 0.8 );
        width: 100% !important;
    }
}