@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';


@media (min-width: $breakpoint-xs) {
    #service-choice {
        background: rgb(234, 234, 234) !important;
        background: -moz-linear-gradient(180deg, rgba(234, 234, 234, 1) 0%, rgba(255, 255, 255, 1) 66%) !important;
        background: -webkit-linear-gradient(180deg, rgba(234, 234, 234, 1) 0%, rgba(255, 255, 255, 1) 66%) !important;
        background: linear-gradient(180deg, rgba(234, 234, 234, 1) 0%, rgba(255, 255, 255, 1) 66%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eaeaea", endColorstr="#ffffff", GradientType=1) !important;


        .option-header {
            font-size: 30px;
            margin-left: 0;
            opacity: .7;
            font-weight: 800;     
            line-height: 30px;   
            
        }

        .options {
            overflow-x: scroll;            
            padding-bottom: 50px;
            padding-top: 20px;
            box-sizing: content-box;
             

        }

        h2 {
            text-align: left;
        }

        .loan-for {
            position: relative;
            border-radius: 100%;
            height: 200px;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;    
            margin-bottom: 30px;      
            padding: 0;  
            overflow: hidden;    
            margin: 0 10px;            
            
            transition: all .2s ease-in-out;

            h4 {
                position: absolute;
                bottom: -100px;
                color: white;
                
                backdrop-filter: blur(10px);
                background-color: rgba(0, 0, 0, 0.417);
                width: 100%;
                padding-bottom: 100px;        
                text-shadow: 0 0 10px rgba(0, 0, 0, 0);          
            }

            * {
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all .2s ease-in-out;

                img {
                    width: 120% !important;                    
                }
            }
            
            
            &:hover {
                transform: scale(1.2);
                cursor: pointer;

                h4 {
                    background-color: rgba(0, 145, 255, 0);    
                    backdrop-filter: blur(0px);                       
                    font-size: 20px;
                    bottom: -55px
                }

                * {
                    img {
                        width: 100% !important;
                        filter: brightness(.7);
                    }
                }

            }
        }
        

        .icon-container {
            background-color: $darkgray-color;

            height: 50px;
            width: 50px;
            padding: 20px;
            display: flex;
            border-radius: 100%;
        }

        hr {
            background-color: black;
            opacity: .2;
            height: 1px;
            width: 100%;


        }

        h2 {
            margin: 0;
            width: 100% !important;
            background: rgb(0, 0, 0);
            background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.13769257703081228) 0%, rgba(255, 164, 0, 0) 66%);
            background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.13769257703081228) 0%, rgba(255, 164, 0, 0) 66%);
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.13769257703081228) 0%, rgba(255, 164, 0, 0) 66%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#ffa400", GradientType=1);
            padding: 20px;
            border-radius: 10px;
            margin-bottom: 50px;            
        }

        h3 {
            width: 100%;

        }

        p {
            line-height: 20px;
        }

    }

}

@media (min-width: $breakpoint-sm) {
    #service-choice {
        .mobile-hr {
            display: none !important;
        }

        .loan-for {
            margin: 0 10px;
        }

        .options {
            display: flex;
            padding-top: 0;
            overflow: visible !important;  
        }

        .option-header {
            font-size: 30px;
            margin-left: 20px;
            opacity: .7;
            font-weight: 800;            
        }

        .icon-container {
            background-color: $darkgray-color;

            height: 50px;
            width: 50px;
            padding: 20px;
            display: flex;
            border-radius: 100%;
        }

        h3 {
            font-size: $font-size-lg;
            opacity: .9;
        }


    }


}