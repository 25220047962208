// Your mixins here
@mixin inset-shadow {
    box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.2) inset;
    -webkit-box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.2) inset;
    -moz-box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.2) inset;
}

@mixin debug-title($content, $background-color) {
    content: $content;
    background-color: $background-color;
    color: white;
    position: absolute;
    top: 0;
    z-index: 1;
    opacity: .5;
    cursor: pointer;
}

@function luminance($color) {
    $red: red($color) / 255;
    $green: green($color) / 255;
    $blue: blue($color) / 255;
    $luminance: 0.2126 * $red + 0.7152 * $green + 0.0722 * $blue;
    @return $luminance;
}

@mixin dynamic-text-color($background) {
    $light-threshold: 0.5; // Adjust as needed

    color: if(luminance($background) > $light-threshold, #000, #ffffffe8);
}

@mixin dynamic-fill-color($background) {
    $light-threshold: 0.5; // Adjust as needed

    fill: if(luminance($background) > $light-threshold, #000, #ffffffe8);
}

@mixin dynamic-border-color($background) {
    $light-threshold: 0.5; // Adjust as needed

    border: 1px solid if(luminance($background) > $light-threshold, #000, #ffffffe8);
}

@mixin dynamic-text-decoration-color($background) {
    $light-threshold: 0.5; // Adjust as needed

    text-decoration-color: if(luminance($background) > $light-threshold, #000, #ffffffe8);
}

@mixin dynamic-invert($background) {
    $light-threshold: 0.5; // Adjust as needed

    filter: invert(if(luminance($background) > $light-threshold, 0, 1));
    
}

@mixin underline-default() {
    text-decoration: underline 0.15em #00000000;
    text-underline-offset: 0.2em;
    transition: all .2s;
}

@mixin underline-hover() {
    color: $secondary-color;
    text-decoration-color: $secondary-color; 
    text-underline-offset: 0.4em;
    transition: all .2s;
}