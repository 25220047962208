@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';


@media (min-width: $breakpoint-xs) {
    #about {
        h2 {
            text-align: center;
            line-height: 60px;            
            margin: 0 !important;      
            color: white;                
            opacity: 1;
        }

        p {
            text-align: center;
        }

        .br-desktop { 
            display: none; 
        }

        .achievment-title {
            margin-bottom: 60px;
            font-weight: 600;
        }

        .loan-for {
            border-radius: 100%;
            height: 200px;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;            

            h3 {
                text-shadow: 0 0 10px black;
            }
        }

        .achievment-value {
            font-size: $font-size-xl !important;
            font-weight: $font-weight-bold;
            text-shadow: $box-shadow;


            position: relative;
            top: -10px !important;

        }

        box-shadow: $box-shadow;

        .card {
            width: 300px;
            height: 400px;

            .next-row {
                margin-top: 10px;

                span {
                    margin-right: 10px;
                    font-weight: 600;
                }
            }

            h3 {
                background-color: rgba(0, 0, 0, 0.3);
                color: white;
                padding: 5px 10px;
                border-radius: $border-radius;
                font-size: 25px;

            }

            .card-image {
                height: 200px;
                width: 200px;
                border-radius: 100% !important;
                overflow: hidden;

                img {
                    width: auto;

                }
            }


        }

        .swiper {
            width: 300px;
            height: 400px;
            padding: 50px;
        }

        .swiper-slide {
            background-position: center;
            background-size: cover;
            width: 300px;
            height: 400px;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
        }

    }

}

@media (min-width: $breakpoint-sm) {

    #about {
        

        .br-mobile {
            display: none;
        }
    }


}