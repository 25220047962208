@import '../../assets/styles/variables';

.loading-container {
    width: 100%;
    height: 100vh;
    background-color: $primary-color;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}