@import '../../../assets/styles/variables';

@media (min-width: $breakpoint-xs) {
    #calculator {
        width: 100%;
        margin-bottom: 100px;
        
        backdrop-filter: blur(10px);
        background-color: rgba($color: #ffffff, $alpha: .2) !important;
    
        button {                        
            height: 50px !important
        }

        .calculator-icon {
            margin-left: 20px;
        }
    
        .calculator-title {            
            font-weight: $font-weight-light;               
               
        }

        .form-headline {
            font-size: 30px !important;
            font-weight: 600;
        }

        .calc-content {
            justify-content: center !important;
        }

        .payment {
            text-align: center;
        }
    
        .amount-container {
            input {        
                //border: none;       
                width: 120px !important;
                background: none;
                margin-left: 10px;
        
                &:focus {            
                    box-shadow: none;
                }
            }
    
            background-color: $white-color;
    
            .edit-icon {
                position: absolute;
                right: 60px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 0;
            }
        
        }
    
        .amount-container, .payment-container {
            padding: 15px;
            border-radius: $border-radius;
            box-sizing: border-box;
        }
    }
    
}

@media (min-width: $breakpoint-sm) {
    #calculator {
        width: 60%;
        
        backdrop-filter: blur(10px);
        background-color: rgba($color: #ffffff, $alpha: .2) !important;
    
        button {
            color: $black-color  
        }
    
        .calculator-title {
            font-weight: $font-weight-light;   
               
        }

        .payment {
            text-align: left;
        }

        .calc-content {
            justify-content: flex-start !important;
        }
    
        .amount-container {
            input {        
                //border: none;       
                width: 120px !important;
                background: none;
                margin-left: 10px;                              
                
                padding-top: 9px;
        
                &:focus {            
                    box-shadow: none;
                }
            }
    
            background-color: $white-color;
    
            .edit-icon {
                position: absolute;
                right: 60px !important;
                top: 50%;
                transform: translateY(-50%);
                z-index: 0;
            }
        
        }
    
        .amount-container, .payment-container {
            padding: 15px;
            border-radius: $border-radius;
            box-sizing: border-box;
        }
    }
    
}


