@import '../../../../assets/styles/_variables';

$checkbox-color: $secondary-color;

.checkbox {
    button {
        position: relative;
        height: 25px;
        width: 25px;
        padding: 0;
        margin: 0;
        margin-right: 10px;
        border-radius: $input-border-radius;
        border: $input-border-width solid $input-border-color;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        

        input {
            padding: 0;
            margin: 0;
            opacity: 0;
            z-index: 2;
        }

        input, div {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
        
        div {
            z-index: 1;
        }
    }

    svg {
        stroke: $checkbox-color;
    }
}
