$header-height: 95vh;

// Header styles

@media (min-width: $breakpoint-xs) {
    #header {
        height: auto;        
        display: flex;
        flex-direction: column;            
        position: relative;    
        background-color: $primary-color;
        
        .steuerPopup {
            background-color: rgba(255, 255, 255, 1) !important;
            backdrop-filter: blur(10px);
            margin-bottom: 20px;


            span {
                font-weight: 500;
            }

            .s-header {
                font-weight: 600 !important;
            }
        }


        b {
            color: $secondary-color;            
        }

        h1, .header-slogan {
            text-shadow: 0 0 20px rgba($color: #000000, $alpha: .3);
            line-height: 40px;
            font-size: 40px;
            margin-top: 150px;
            text-align: center;
            margin-bottom: 20px;
        }
        .header-slogan {
            margin-top: 0;
            font-size: 23px !important;
            width: 100%;
        }

        .header-video {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            height: 100%;
            width: auto;
        }

        .header-image {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            background-color: red;
            width: 100%;
            height: 100%;
            filter: brightness(.7);            
            

            img {
                width: 100% !important;           
                object-fit: cover !important;     
                
            }
            
        }

        .header-background {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;


            background: rgb(255, 255, 255);
            background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
            background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=1);
            opacity: .7;
        }                

        .header-slogan {
            font-size: $font-size-lg;
        }
    }

}

@media (min-width: $breakpoint-sm) {

    #header {
        height: $header-height; 

        h1, .header-slogan {
            font-size: $font-size-xl;
            text-align: left;
            text-shadow: 0 0 20px rgba($color: #000000, $alpha: .3);
            line-height: 60px;  
            margin-top: 0;                                 
        }        

        .header-video {
            position: absolute;
            width: 100%;

            object-fit: cover;
        }

        .header-bg {
            max-width: 1500px;
            position: absolute;
            z-index: 0;
            left: 50%;
            transform: translateX(-50%);
            bottom: -200px;
            opacity: .3;
        }
    }
           
}