@import '../../assets/styles/variables';



@media (min-width: $breakpoint-xs) {
    #immobilien {
        h2 {
            line-height: 40px;
            font-size: 30px;
            opacity: 1;

        }

        p {
            margin-bottom: 50px;
        }

        .button-secondary {
            * {
                color: white !important;


            }
        }

        .content {
            background-color: rgba(0, 128, 255, 0.7) !important;
            backdrop-filter: blur(20px);
        }

        button, a {
            width: 100% !important;
            height: 70px;
            margin-bottom: 10px;
        }
    }

}

@media (min-width: $breakpoint-sm) {
    #immobilien {
        button, a {
            height: 60px;
        }

        h2 {
            line-height: 60px;
            font-size: $font-size-xl;

        }

        p {
            margin-bottom: 50px;
        }

        .button-secondary {
            * {
                color: white !important;


            }
        }
    }

}