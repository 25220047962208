@import './variables';
@import './mixins';
@import './navbar';
@import './footer';


// Your base styles here

@media (min-width: $breakpoint-xs) {
    .App {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }


    body,
    html {
        scroll-behavior: smooth;
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        * {
            font-family: $body-font;
        }
        

        margin: 0;
        padding: 0;

        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        background: $background-color;

        * {
            -webkit-tap-highlight-color: transparent;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            z-index: 1;
        }

        #top-space {
            padding: $mobile-vertical-padding;

            &::after {
                content: '';
                display: block;
                margin: 0;
                padding: 0;
                height: $logo-mobile-height;
            }
        }

        #footer-container {
            display: flex;
            align-items: flex-end;
        }



        img {
            pointer-events: none;
        }

        .link {
            cursor: pointer;
            opacity: .8;

            @include underline-default();
            text-decoration: underline 0.1em #00000000;

            &:hover {
                opacity: 1;

                text-underline-offset: 0.3em;
                transition: all .2s;
            }
        }

        hr {
            border: 1px solid white;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        h1 {
            font-size: $font-size-xl;
        }

        h2 {
            font-size: 40px;
            font-weight: $font-weight-light;
            line-height: 40px;
            position: relative;
            opacity: .85;       
            margin-top: 0;
            
            
        }

        .map {
            height: 300px;
        }
        

        .dot {

            font-size: 100px;
            margin: 0;
            padding: 0;
            height: auto;
            color: rgb(255, 179, 0);
            

        }

        h3 {
            margin-bottom: 5px;            
            font-weight: 600;
        }

        h4 {
            margin-top: 0;
        }

        a {
            text-decoration: none;
        }

        p,
        input {
            margin: 10px 0;
            font-size: $font-size-sm;
        }

        p {
            line-height: 30px;
        }

        span,
        label,
        a {
            font-size: $font-size-md;
        }

        .side-section {
            padding: 0;
        }

        .side-span {
            font-size: $font-size-xl !important;
            margin: 20px 0;
            opacity: .5;
            font-weight: 600;


        }

        form {
            width: 100%;
        }
    }
}

@media (min-width: $breakpoint-sm) {
    body {
        #top-space {
            padding: $desktop-vertical-padding !important;

            &::after {
                content: '';
                display: block;
                height: $logo-desktop-height !important;
            }
        }

        .map {
            height: 500px;
        }


        h2 {
            font-size: $font-size-xl;
            font-weight: $font-weight-light;
            line-height: 60px;
            position: relative;
            opacity: .85;       
            margin-top: 0;
            
            
        }
    }
}