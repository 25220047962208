@import '../../../assets/styles/variables';

@media (min-width: $breakpoint-xs) {
    #quickContact {
        position: fixed;
        z-index: 9999;
        bottom: 0;
        right: 0;
        margin: 10px !important;    
    
        .contact-icon {
            height: 100px;
            width: 100px;
            cursor: pointer;        
        }
    
        button, a {
            width: 100% !important;            
        }

        span {
            text-align: center;
        }
    
        .center-button {
            margin: 10px 0;
        }
        .contact-headline {
            font-weight: 600;
            font-size: 24px;
        }
    
        .close-button {
            position: absolute;
            top: 20px !important;
            right: 20px !important;
            height: 20px !important;
            width: 20px !important;
            padding: 0 !important;
            border: none;
            background: none;
        }
    
        .call-content {
            box-shadow: 0 0 20px rgba($color: #000000, $alpha: .2);
            width: 100% !important;
        }
    }
}

@media (min-width: $breakpoint-sm) {
    #quickContact {
        position: fixed;
        z-index: 9999;
        bottom: 20px;
        right: 20px;    
    
        .contact-icon {
            height: 100px;
            width: 100px;
            cursor: pointer;        
        }
    
        button {
            width: 200px !important;
        }
    
        .center-button {
            margin: 0 20px;
        }
        .contact-headline {
            font-weight: 600;
            font-size: 24px;
        }
    
        .close-button {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 20px;
            width: 20px;
            padding: 0 !important;
            border: none;
            background: none;
        }
    
        .call-content {
            box-shadow: 0 0 20px rgba($color: #000000, $alpha: .2);
        }
    }
}


