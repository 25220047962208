@import '../../assets/styles/variables';

@media (min-width: $breakpoint-xs) {
    #contact {
        h2 {
            margin: 0;
            text-align: center;
            line-height: 40px;
        }
    
        p {
            width: 70%;
            margin-top: 20px;
            line-height: 25px;
            opacity: .7;
            font-weight: 500;

            text-align: center;
        }
    
        label {
            opacity: .8;
        }
    
        button {
            margin-top: 20px;
            width: 200px;
        }
    
        span {
            opacity: .6;
        }
    
        
        
        
    }
}

@media (min-width: $breakpoint-sm) {
    #contact {
        h2 {
            margin: 0;
            text-align: left;
            line-height: 55px;
        }
    
        p {
            width: 70%;
            margin-top: 20px;
            line-height: 25px;
            opacity: .7;
            font-weight: 500;
            text-align: left;
        }
    
        label {
            opacity: .8;
        }
    
        button {
            margin-top: 20px;
            width: 200px;
        }
    
        span {
            opacity: .6;
        }
    
        
        
        
    }
}

