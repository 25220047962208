@import '../../assets/styles/variables';

@media (min-width: $breakpoint-xs) {
    #faq {
        h2 {
            text-align: center;
            margin-bottom: 50px;
        }
    }
    
}

@media (min-width: $breakpoint-sm) {

}