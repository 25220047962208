.switch {
    width: 70px;
    height: 40px;
    background-color: lightgray;
    margin-left: 10px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    border-radius: 50px;
    padding: 0 6px;
    cursor: pointer;
    box-sizing: border-box;
    transition: all .2s;

    &[data-ison='true'] {
        justify-content: flex-end;
        background-color: lightgreen;
        transition: all .2s;
    }

    .switch-handle {
        width: 30px;
        height: 30px;
        background-color: white;
        border-radius: 40px;
        box-sizing: border-box;
    }    
}

